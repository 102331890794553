import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import classes from './DeleteAccount.module.scss';
import BackButton from "../../components/BackButton/BackButton";

class DeleteAccount extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className={classes.DeleteAccount}>
                <BackButton/>

                <div>
                    <h3>{this.props.t('translations:deleteAccount.headline')}</h3>

                    <p>{this.props.t('translations:deleteAccount.content')}</p>
                </div>
            </div>
        );
    }
}

export default withTranslation()(DeleteAccount);