import React from 'react';
import classes from "./SocialMedia.module.scss"
import facebook from '../../assets/images/karls-icon-facebook.png';
import instagram from '../../assets/images/karls-icon-instagram.png';
import youtube from '../../assets/images/karls-icon-youtube.png';

const SocialMedia = (props) => {
    return (
        <div className={[classes.SocialMedia, props.className].join(' ')}>
            <a href="https://www.facebook.com/karls.jobs" target='_blank'>
                <img src={facebook} alt="facebook"/>
            </a>
            <a href="https://www.instagram.com/karls_erdbeerhof_/" target='_blank'>
                <img src={instagram} alt="instagram"/>
            </a>
            <a href="https://www.youtube.com/channel/UCysTsR6m4mGNoRzEauCeH3g" target='_blank'>
                <img src={youtube} alt="youtube"/>
            </a>
        </div>
    );
}

export default SocialMedia;