import React from "react";
import classes from './Home.module.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useTranslation} from "react-i18next";
import PickerAppPreview from '../../assets/images/PickerAppPreview.jpg';

export const AppAd = () => {
  const {t} = useTranslation();

  return <div className={classes.pickerAppPreview}>
    <img src={PickerAppPreview} alt="picker app preview"/>
    <div><p>{t('translations:advertisementText')}</p></div>
  </div>

}
