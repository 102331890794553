import React from "react";
import classes from './Home.module.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useTranslation} from "react-i18next";
import MoreExperience from '../../assets/images/moreExperience.svg';
import ExperienceGrey from '../../assets/images/experienceGrey.svg';
import ExperienceGreen from '../../assets/images/experienceGreen.svg';
import AvatarFirst from '../../assets/images/avatarFirst.jpg';
import AvatarSecond from '../../assets/images/avatarSecond.jpg';
import AvatarThird from '../../assets/images/avatarThird.jpg';

export const Experiences = () => {
  const {t} = useTranslation();
  const experiences = [
    {
      text: t('translations:experiences.first'),
      img: AvatarFirst
    },
    {
      text: t('translations:experiences.second'),
      img: AvatarSecond
    },
    {
      text: t('translations:experiences.third'),
      img: AvatarThird
    },
  ]

  return <div className={classes.experiences}>
    <h2>{t('translations:experiences.headline')}</h2>
    <p>{t('translations:experiences.text')}</p>

    <div className={classes.singleExperienceContainer}>
      {Object.entries(experiences).map(([key, value]) =>
        <div {...{key}} className={classes.singleExperience}>

          {key % 2 === 1 && <p>{value.text}</p>}

          <div className={classes.avatar}>
            <img className={classes.avatarBackground}
                 src={key % 2 === 0 ? ExperienceGrey : ExperienceGreen}
                 alt="experience border"/>
            <img src={value.img} className={classes.avatarImage}
                 alt="avatar"/>
          </div>

          {key % 2 === 0 && <p>{value.text}</p>}
        </div>
      )}
    </div>

    <div className={classes.moreExperiences}>
      <img src={MoreExperience} alt="more experiences"/>
      <p>{t('translations:experiences.buttonText')}</p>
    </div>
  </div>

}
