import classes from './App.module.scss'
import '../utilities/i18n'
import {Home} from "./Home/Home";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Impressum from "./Impressum/Impressum";
import {Tracking} from "./Tracking/Tracking";
import Privacy from "./Privacy/Privacy";
import {isMobile} from 'react-device-detect';
import AppStore from "./AppStore/AppStore";
import DeleteAccount from "./DeleteAccount/DeleteAccount";
import {API} from "../api/pickerBackend";
import {Suspense, useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ReactGA from 'react-ga';
import classNames from 'classnames/bind';

function App() {
  const [languages, setLanguages] = useState(null);

  useEffect(() => {
    ReactGA.initialize('UA-13153497-15');
    ReactGA.pageview(window.location.pathname);

    languages || API.languages().then(r => setLanguages(r.data));
  }, []);

  const content = (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route path="/tracking" element={<Tracking/>}/>
        <Route path="/impressum" element={<Impressum/>}/>
        <Route path="/deleteAccount" element={<DeleteAccount/>}/>
        <Route path="/privacy" element={<Privacy/>}/>
        <Route path="/appstore" element={<AppStore/>}/>
      </Routes>
    </BrowserRouter>
  );

  const contentClassNames = classNames.bind(classes)({
    content: true,
    isNotMobile: !isMobile
  });

  return (
    <Suspense fallback={null}>
      <Header hasReturn={true} languages={languages}/>

      <div className={contentClassNames}>{content}</div>

      <Footer/>
    </Suspense>
  );
}

export default App;
