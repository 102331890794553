import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import classes from './Impressum.module.scss';
import BackButton from "../../components/BackButton/BackButton";

class Impressum extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className={classes.Impressum}>
                <BackButton />

                <div className={classes.content}>
                    <h3>Karls Markt OHG</h3>

                    <p>Purkshof 2<br/>
                        18182 Rövershagen<br/>
                        Telefon: 038202-40 50<br/>
                        Fax: 038202-40 52 23</p>

                    <p>E-Mail: <a href="mailto:post@karls.de">post@karls.de</a><br/>
                        Internet: <a href="https://www.karls.de">www.karls.de</a></p>

                    <p><span>Karls Markt OHG wird vertreten durch:</span> Geschäftsführer Robert Dahl</p>

                    <p><span>Registergericht:</span> Amtsgericht Rostock<br/>
                        <span>Handelsregister Registernummer:</span> HRA 3137</p>

                    <p><span>Gaststättenerlaubnis erteilt durch:</span> Landkreis Bad Doberan, Der Landrat, Planungsamt, August-Bebel-Straße 3, 18209 Bad Doberan<br/>
                        <span>Zuständige Aufsichtsbehörde:</span> Amt Rostocker Heide, Eichenallee 20, 18182 Gelbensande</p>

                    <p><span>Umsatzsteuer-Identifikationsnummer gemäß§ 27a Umsatzsteuergesetz (UStG):</span> DE275282235<br/>
                        <span>Inhaltlich Verantwortlicher gemäß § 55 Rundfunkstaatsvertrag (RStV):</span> Robert Dahl, Purkshof 2, 18182 Rövershagen</p>

                    <p><span>Haftungshinweis:</span> Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</p>

                    <p><span>Fotos- Bildrechte:</span> © Karls Markt OHG</p>

                    <p><span>Copyright:</span> 2018, Karls Markt OHG</p>

                    <p>All rights reserved. Any reproduction, duplication, or distribution of files from this website in any form is prohibited.</p>

                    <p>Alle Rechte vorbehalten. Jedwede Reproduktion, Duplizierung oder Veröffentlichung von Inhalten dieser Website bedarf der schriftlichen Genehmigung und ist untersagt. Das Unternehmen Karls Markt OHG ist nicht verantwortlich für den Inhalt von Websites, auf die die Karls Markt OHG - Internetseite (www.karls.de) verlinkt oder die auf die Karls Markt OHG - Internetseite verlinken.</p>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Impressum);