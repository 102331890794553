import React, {Component} from "react";
import {withTranslation} from 'react-i18next';
import classes from './Privacy.module.scss';
import BackButton from "../../components/BackButton/BackButton";

class Privacy extends Component {
        componentDidMount() {
                window.scrollTo(0, 0);
        }

        render() {
        return (
            <div className={classes.Privacy}>
                <BackButton />
                <h2>{this.props.t('default:privacyText.title')}</h2>

                <p>{this.props.t('default:privacyText.p1')}</p>

                <h3>{this.props.t('default:privacyText.h2')}</h3>
                <p>{this.props.t('default:privacyText.p2')}</p>

                <h3>{this.props.t('default:privacyText.h3')}</h3>
                <p>{this.props.t('default:privacyText.p3')}</p>

                <h3>{this.props.t('default:privacyText.h4')}</h3>
                <p>{this.props.t('default:privacyText.p4')}</p>

                <h3>{this.props.t('default:privacyText.h5')}</h3>
                <p>{this.props.t('default:privacyText.p5')}</p>

                <h3>{this.props.t('default:privacyText.h6')}</h3>
                <p>{this.props.t('default:privacyText.p6')}</p>

                <h3>{this.props.t('default:privacyText.h7')}</h3>
                <p>{this.props.t('default:privacyText.p7')}</p>

                <h3>{this.props.t('default:privacyText.h8')}</h3>
                <p>{this.props.t('default:privacyText.p8')}</p>

                <h3>{this.props.t('default:privacyText.h9')}</h3>
                <p>{this.props.t('default:privacyText.p9')}</p>

                <h3>{this.props.t('default:privacyText.h10')}</h3>
                <p>{this.props.t('default:privacyText.p10')}</p>

                <h3>{this.props.t('default:privacyText.h11')}</h3>
                <p>{this.props.t('default:privacyText.p11')}</p>

                <h3>{this.props.t('default:privacyText.h12')}</h3>
                <p>{this.props.t('default:privacyText.p12')}</p>

                <h3>{this.props.t('default:privacyText.h13')}</h3>
                <p>{this.props.t('default:privacyText.p13')}</p>
            </div>
        );
    }
}

export default withTranslation()(Privacy);