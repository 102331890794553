import React from "react";
import classes from "./Header.module.scss"
import LanguageSelector from "../../components/LanguageSelector/LanguageSelector";
import logo from "../../assets/images/Karls_Logo.svg";
import ReturnButton from "../../components/ReturnButton/ReturnButton";

const Header = props => {
    let returnButton = null;
    if (props.hasReturn === true) {
        returnButton = <ReturnButton/>;
    }

    return (
        <header>
            {returnButton &&
            <div>
                {returnButton}
            </div>
            }

            <a href="/">
                <img className={classes.logo} src={logo} alt='Karls'/>
            </a>

            <div className={classes.languageSelector}>
                <LanguageSelector languages={props.languages}/>
            </div>
        </header>
    );
}

export default Header;