import {api} from "./axiosConfig";

const apiRequest = (method) =>
  async (url, data, options) => {
    const response = await api.request({
      url,
      params: options?.params,
      headers: options?.headers,
      data,
      method,
      responseType: options?.responseType
    });

    return response.data;
  };

export const API = {
  languages: async () => await apiRequest('GET')('/languages'),
  sendContactForm: async (data) => await apiRequest('POST')('/sendContactForm', data),
  tracking: async (data) => await apiRequest('POST')('/tracking/new', data),
  patchTracking: async (id, data) => await apiRequest('POST')('/tracking/' + id, data),
};
