export default function validateTelephone(value) {
    if (value === undefined || value.length === 0) {
        return 'required';
    }

    if (value.length > 25) {
        return 'moreThan';
    }

    if (value.length < 5) {
        return 'lessThan';
    }
}