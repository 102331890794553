import React, {useRef} from 'react';
import classes from "./FooterLink.module.scss";
import {useTranslation} from "react-i18next";
import Popup from "reactjs-popup";
import iosButton from '../../assets/images/iosAppButton.png';
import androidButton from '../../assets/images/androidAppButton.png';

const FooterLink = () => {
    const {t} = useTranslation();
    const popup = useRef({});
    const styling = {
        background: 'transparent',
        width: '100%',
        padding: '0 1rem',
        border: 'none',
        margin: '0'
    };
    const overlayStyling = {background: 'rgba(0,0,0,0.9)', zIndex: 1001};

    let trigger = <p>{t('makeApplication')}</p>
    return (
        <div className={classes.footerLink}>
            <Popup
                modal
                position='top center'
                ref={popup}
                trigger={trigger}
                contentStyle={styling}
                overlayStyle={overlayStyling}
                lockScroll={true}
                closeOnDocumentClick={false}>
                {close => (
                    <div className={classes.appLink}>
                        <a href='https://itunes.apple.com/de/app/karls-picker-app/id1564048250'><img
                            src={iosButton} alt=""/></a>
                        <a href='https://play.google.com/store/apps/details?id=de.karls.pickerapp'><img
                            src={androidButton} alt=""/></a>
                        <button onClick={close}>{t('default:close')}</button>
                    </div>
                )}
            </Popup>
        </div>
    );
}

export default FooterLink;