import React from "react";
import classes from './Home.module.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useTranslation} from "react-i18next";
import NoCostsButton from '../../assets/images/noCostsButton.png';
import NoCostsButtonUa from '../../assets/images/noCostsButton-ua.png';
import NoCostsButtonPl from '../../assets/images/noCostsButton-pl.png';
import NoCostsButtonRo from '../../assets/images/noCostsButton-ro.png';

const noCostButton = {
    'ua': NoCostsButtonUa,
    'pl': NoCostsButtonPl,
    'ro': NoCostsButtonRo,
    'default': NoCostsButton,
}

export const Header = ({language}) => {
    const {t} = useTranslation();
    const recruitingVideo = process.env.REACT_APP_MEDIA_ROOT + 'videos/recruiting-' + language + '.mp4';

    const noCost = noCostButton[language]
        ?? noCostButton['default'];

    return <>
        <div className={classes.videoWrapper}>
            <video src={recruitingVideo} playsInline muted autoPlay={true} controls={true}/>
        </div>

        <div className={classes.moreInfos}>
            <div className={classes.moreInfosWrapper}>
                <h3>{t('translations:moreInfos.headline')}</h3>
                <p>{t('translations:moreInfos.text')}</p>
                <p className={classes.info}>{t('translations:moreInfos.info')}</p>
            </div>
        </div>

        <img className={classes.noCostsButton} src={noCost} alt="no costs"/>
    </>
}
