import React from 'react';
import classes from './InputText.module.scss';
import {useTranslation} from 'react-i18next';

const InputText = (props) => {
    const {t, i18n} = useTranslation();

    let information;
    if (i18n.exists('translations:form.' + props.name + '.information')) {
        information = t('translations:form.' + props.name + '.information');
    }

    return (
        <div>
            <label htmlFor={props.name} className={classes.inputLabel}>
                <div className={classes.label}>
                    {t('translations:form.' + props.name + '.name')} {(props.required ? '*' : '')}
                </div>

                <input type="text" autoComplete='off' name={props.name} id={props.name}
                       onChange={props.change} onBlur={props.blur} className={classes.inputText}
                       placeholder={t('translations:form.' + props.name + '.placeholder')}
                       defaultValue={props.value}/>
                {props.noInfo &&
                <p>{information}</p>
                }
            </label>
            {props.error &&
            <p className='error'>{t('translations:form.' + props.name + '.' + props.error)}</p>}
        </div>
    );
}

export default InputText;