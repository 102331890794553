import React, {useState} from 'react';
import classes from "./ContactForm.module.scss";
import Firstname from "../../components/Formular/Firstname/Firstname";
import Name from "../../components/Formular/Name/Name";
import Email from "../../components/Formular/Email/Email";
import Telephone from "../../components/Formular/Telephone/Telephone";
import validateContactForm from "../../utilities/validateContactForm";
import {useTranslation} from "react-i18next";
import {API} from "../../api/pickerBackend";

const ContactForm = (props) => {
    const [contactData, setContactData] = useState({});
    const [errors, setErrors] = useState([]);
    const [success, setSuccess] = useState(null);
    const {t} = useTranslation();

    const attributeHandler = (event) => {
        const name = event?.target?.name;
        const value = event?.target?.value;

        name !== null
        && value !== null
        && setContactData(p => ({...p, [name]: value}));
    }

    const handleTelephone = (value, name) => {
        setContactData(p => ({...p, [name]: value}))
    }

    const sendToBackendHandler = () => {
        let errors = validateContactForm(contactData);

        if (errors.validated) {
          API.sendContactForm(contactData).then(
            r => {
              setSuccess(r?.data?.success);
              r.data.success && setErrors(null);
                }
            );
        } else {
            setErrors(errors);
        }
    }

    return (
        <div className={[classes.ContactForm, props.className].join(' ')}>
            <h3>{t('contactForm.title')}</h3>
            {success === true &&
            <div className={classes.success}>
                <div>
                    <p>{t('contactForm.success')}</p>
                </div>
            </div>
            }
            {success === false &&
            <div className={classes.error}>
                <div>
                    <p>{t('contactForm.error')}</p>
                </div>
            </div>
            }
            {success === null &&
            <>
                <div className={classes.flex}>
                    <Firstname change={attributeHandler} error={errors?.firstname}/>
                    <Name change={attributeHandler} error={errors?.name}/>
                </div>

                <Email change={attributeHandler} error={errors?.email}/>

                <Telephone change={handleTelephone} error={errors?.telephone}/>

                <div className={classes.Button}
                     onClick={sendToBackendHandler}>{t('contactForm.applyNow')}</div>
            </>
            }
        </div>
    );
}

export default ContactForm;
