import React from 'react';
import {BrowserRouter, Link} from "react-router-dom";
import returnImage from '../../assets/images/Karls_Erlebnis-Dorf_Logo.png';

const ReturnButton = () => {
    return (
        <div>
            <BrowserRouter>
                <Link to="/">{/*<img src={returnImage} alt=""/>*/}</Link>
            </BrowserRouter>
        </div>
    );
}

export default ReturnButton;