import classes from './Tracking.module.scss';
import {useEffect} from "react";
import {API} from "../../api/pickerBackend";
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {createTracking} from "../../utilities/util";

export const Tracking = () => {
  const [search] = useSearchParams();
  const {i18n: {language: lang}} = useTranslation();
  const {t} = useTranslation();

  useEffect(() => {
      if (window.name === 'karls.pickerapp.tracker') window.close()
      window.location.href = 'de.karls.pickerapp://'
      const deviceId = search.get('deviceId')
      if (!deviceId) return

      const tracking = {
        ...{deviceId}, ...(
          JSON.parse(localStorage.getItem('tracking') ?? 'null')
          ?? createTracking('AppOnly', lang)
        )
      }

      API.patchTracking(tracking.id, tracking).then(() => {
        window.open(window.location, "_self").name = 'karls.pickerapp.tracker'
        window.close()
      })
    }, []
  )
  return <div className={classes.Tracking}>
    <h2>{t('tracking.header')}</h2>
    <p>{t('tracking.text')}</p>
    <p><a href='de.karls.pickerapp://'>{t('tracking.return')}</a></p>
  </div>;
}

