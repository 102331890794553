import React from "react";
import { useNavigate } from 'react-router-dom';
import IconArrow from "../../assets/images/iconArrow.png";
import classes from "./BackButton.module.scss"
import {useTranslation} from "react-i18next";

export default function BackButton() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    return (
        <div className={classes.BackButton} onClick={goBack}>
            <img src={IconArrow} alt={t('arrow')}/>
        </div>
    )
}