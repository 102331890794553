import React from 'react';
import {useTranslation} from 'react-i18next';
import classes from "./TrainingVideos.module.scss";
import Popup from "reactjs-popup";
import ReactPlayer from "react-player";
import {isMobile} from 'react-device-detect';
import classNames from "classnames/bind";

const TrainingVideos = ({language}) => {
    const {t} = useTranslation();
    const styling = {background: 'transparent', width: '100%', padding: '0 1rem', border: 'none', margin: '0'};
    const overlayStyling = {background: 'rgba(0,0,0,0.9)'};

    let cx = classNames.bind(classes);
    const contentClassNames = cx({
        TrainingVideo: true,
        isNotMobile: !isMobile
    });

    return (
        <div className={contentClassNames}>
            <div>
                <Popup
                    modal
                    open={false}
                    position='top center'
                    contentStyle={styling}
                    overlayStyle={overlayStyling}
                    lockScroll={true}
                    closeOnDocumentClick={true}
                    trigger={<img src={process.env.REACT_APP_MEDIA_ROOT + 'images/tutorials/tutorial1' + '-' + language + '-thumb.png'} alt="Vorschaubild"/>}
                >
                    {close => (
                        <div className={classes.Player}>
                            <ReactPlayer
                                url={process.env.REACT_APP_MEDIA_ROOT + 'videos/tutorial1' + '-' + language + '.mp4'}
                                controls={true}
                                width='100%'
                                height='100%'
                                playing={true}
                                config={{
                                    file: {}
                                }}
                            />

                            <button onClick={close}>{t('default:close')}</button>
                        </div>
                    )}
                </Popup>
            </div>

            <div>
                <Popup
                    modal
                    open={false}
                    position='top center'
                    contentStyle={styling}
                    overlayStyle={overlayStyling}
                    lockScroll={true}
                    closeOnDocumentClick={true}
                    trigger={<img src={process.env.REACT_APP_MEDIA_ROOT + 'images/tutorials/tutorial2' + '-' + language + '-thumb.png'} alt="Vorschaubild"/>}
                >
                    {close => (
                        <div className={classes.Player}>
                            <ReactPlayer
                                url={process.env.REACT_APP_MEDIA_ROOT + 'videos/tutorial2' + '-' + language + '.mp4'}
                                controls={true}
                                width='100%'
                                height='100%'
                                playing={true}
                                config={{
                                    file: {}
                                }}
                            />

                            <button onClick={close}>{t('default:close')}</button>
                        </div>
                    )}
                </Popup>
            </div>

            <div>
                <Popup
                    modal
                    open={false}
                    position='top center'
                    contentStyle={styling}
                    overlayStyle={overlayStyling}
                    lockScroll={true}
                    closeOnDocumentClick={true}
                    trigger={<img src={process.env.REACT_APP_MEDIA_ROOT + 'images/tutorials/tutorial3' + '-' + language + '-thumb.png'} alt="Vorschaubild"/>}
                >
                    {close => (
                        <div className={classes.Player}>
                            <ReactPlayer
                                url={process.env.REACT_APP_MEDIA_ROOT + 'videos/tutorial3' + '-' + language + '.mp4'}
                                controls={true}
                                width='100%'
                                height='100%'
                                playing={true}
                                config={{
                                    file: {}
                                }}
                            />

                            <button onClick={close}>{t('default:close')}</button>
                        </div>
                    )}
                </Popup>
            </div>

            <div>
                <Popup
                    modal
                    open={false}
                    position='top center'
                    contentStyle={styling}
                    overlayStyle={overlayStyling}
                    lockScroll={true}
                    closeOnDocumentClick={true}
                    trigger={<img src={process.env.REACT_APP_MEDIA_ROOT + 'images/tutorials/tutorial4' + '-' + language + '-thumb.png'} alt="Vorschaubild"/>}
                >
                    {close => (
                        <div className={classes.Player}>
                            <ReactPlayer
                                url={process.env.REACT_APP_MEDIA_ROOT + 'videos/tutorial4' + '-' + language + '.mp4'}
                                controls={true}
                                width='100%'
                                height='100%'
                                playing={true}
                                config={{
                                    file: {}
                                }}
                            />

                            <button onClick={close}>{t('default:close')}</button>
                        </div>
                    )}
                </Popup>
            </div>

            <div>
                <Popup
                    modal
                    open={false}
                    position='top center'
                    contentStyle={styling}
                    overlayStyle={overlayStyling}
                    lockScroll={true}
                    closeOnDocumentClick={true}
                    trigger={<img src={process.env.REACT_APP_MEDIA_ROOT + 'images/tutorials/tutorial5' + '-' + language + '-thumb.png'} alt="Vorschaubild"/>}
                >
                    {close => (
                        <div className={classes.Player}>
                            <ReactPlayer
                                url={process.env.REACT_APP_MEDIA_ROOT + 'videos/tutorial5' + '-' + language + '.mp4'}
                                controls={true}
                                width='100%'
                                height='100%'
                                playing={true}
                                config={{
                                    file: {}
                                }}
                            />

                            <button onClick={close}>{t('default:close')}</button>
                        </div>
                    )}
                </Popup>
            </div>

            <div>
                <Popup
                    modal
                    open={false}
                    position='top center'
                    contentStyle={styling}
                    overlayStyle={overlayStyling}
                    lockScroll={true}
                    closeOnDocumentClick={true}
                    trigger={<img src={process.env.REACT_APP_MEDIA_ROOT + 'images/tutorials/tutorial6' + '-' + language + '-thumb.png'} alt="Vorschaubild"/>}
                >
                    {close => (
                        <div className={classes.Player}>
                            <ReactPlayer
                                url={process.env.REACT_APP_MEDIA_ROOT + 'videos/tutorial6' + '-' + language + '.mp4'}
                                controls={true}
                                width='100%'
                                height='100%'
                                playing={true}
                                config={{
                                    file: {}
                                }}
                            />

                            <button onClick={close}>{t('default:close')}</button>
                        </div>
                    )}
                </Popup>
            </div>
        </div>
    );
}

export default TrainingVideos;