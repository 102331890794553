import React from "react";
import classes from './Home.module.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useTranslation} from "react-i18next";
import PdfDownloadIcon from '../../assets/images/pdfDownloadIcon.svg';

export const Fibel = ({language}) => {
    const {t} = useTranslation();
    const fibel = process.env.REACT_APP_MEDIA_ROOT + 'pdfs/fibel-' + language + '.pdf';

    return <div className={classes.fibel}>
        <h3>{t('translations:fibel.headline')}</h3>
        <p>{t('translations:fibel.text')}</p>
        <div>
            <a href={fibel} target="_blank">
                <img src={PdfDownloadIcon} alt="PDF download"/>
                <p>{t('translations:fibel.buttonText')}</p>
            </a>
        </div>
    </div>
}
