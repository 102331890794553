export default function validateName(value) {
    if (value === undefined || value.length === 0) {
        return 'required';
    }

    if (value.length < 3) {
        return 'lessThan';
    }

    if (value.length > 255) {
        return 'moreThan';
    }
}