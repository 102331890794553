import React, {useEffect} from "react";
import {useTranslation} from 'react-i18next';
import classes from './Home.module.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FooterLink from "../../components/FooterLink/FooterLink";
import TrainingVideos from "../../components/TutorialVideos/TrainingVideos";
import {isMobile} from 'react-device-detect';
import classNames from "classnames/bind";
import SocialMedia from "../SocialMedia/SocialMedia";
import ContactForm from "../ContactForm/ContactForm";
import {Header} from "./Header";
import {Fibel} from "./Fibel";
import {AppAd} from "./AppAd";
import {Experiences} from "./Experiences";
import {useSearchParams} from "react-router-dom";
import {API} from "../../api/pickerBackend";
import {createTracking} from "../../utilities/util";

export const Home = () => {
  const {i18n: {language: lang}} = useTranslation();
  const language = lang.toLowerCase().startsWith('ua')
      ? 'uk'
      : lang.substring(0, 2).toLowerCase();
  const [params] = useSearchParams()

  useEffect(() => {
    if (!!localStorage.getItem('tracking')) return

    const tracking = createTracking(
      params.get('tracking') ?? 'None',
      lang
    )

    API.tracking(tracking)
        .then(() => localStorage.setItem('tracking', JSON.stringify(tracking)))
  }, []);

  const contentClassNames = classNames.bind(classes)({
    home: true,
    isNotMobile: !isMobile
  });

  return <div className={contentClassNames}>
    <Header {...{language}}/>
    <TrainingVideos {...{language}}/>
    <Fibel {...{language}}/>
    <AppAd/>
    <Experiences/>
    <SocialMedia className={classes.SocialMedia}/>
    <ContactForm/>
    <FooterLink/>
  </div>;
}
