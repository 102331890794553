import React from "react";
import {useTranslation} from 'react-i18next';
import classes from "./LanguageSelect.module.scss";
import de from '../../../assets/images/de.jpg';
import ru from '../../../assets/images/ru.jpg';
import ro from '../../../assets/images/ro.jpg';
import en from '../../../assets/images/en.jpg';
import pl from '../../../assets/images/pl.jpg';
import uk from '../../../assets/images/uk.jpg';

const LanguageSelect = props => {
    const {t} = useTranslation();

    let image;
    switch (props.language.isoCode) {
        case 'EN':
            image = <img src={en} className={classes.flag} alt={t('default:languageFlag.enAlt')}/>;
            break;
        case 'RO':
            image = <img src={ro} className={classes.flag} alt={t('default:languageFlag.roAlt')}/>;
            break;
        case 'RU':
            image = <img src={ru} className={classes.flag} alt={t('default:languageFlag.ruAlt')}/>;
            break;
        case 'UK':
        case 'UA':
            image = <img src={uk} className={classes.flag} alt={t('default:languageFlag.ukAlt')}/>;
            break;
        case 'PL':
            image = <img src={pl} className={classes.flag} alt={t('default:languageFlag.plAlt')}/>;
            break;
        default:
            image = <img src={de} className={classes.flag} alt={t('default:languageFlag.deAlt')}/>;
            break;
    }

    return (
        <div className={classes.languageWrapper} onClick={() => props.changeLanguage(props.language.isoCode)}>
            {image}
            <p>{t('translations:languages.' + props.language.isoCode)}</p>
        </div>
    );
}

export default LanguageSelect;
