import i18n from 'i18next'
import LngDetector from "i18next-browser-languagedetector";
import backend from "i18next-xhr-backend";
import {initReactI18next} from 'react-i18next'
import XHR from "i18next-http-backend" // <---- add this

i18n
  .use(LngDetector)
  .use(XHR)
  .use(backend)
  .use(initReactI18next)
  .init({
    load: "languageOnly",
    cleanCode: true,
    ns: ['translations', 'default'],
    supported: ["ua", "de", "ro", "pl"],
    backend: {
      /* translation file path */
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    fallbackLng: 'ua',
    debug: false,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    defaultNS: 'translations',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true
    },
    detection: {
      order: ['navigator', 'queryString'],
      lookupQuerystring: 'lng'
    },

  }, (err) => {
    if (err)
      console.error(err)
  });
