import React, {useState} from 'react';
import classes from './Name.module.scss';
import validateName from "./validateName";
import InputText from "../InputText/InputText";

const Name = (props) => {
    const name = 'name';
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);

    function validate(event) {
        let validated = validateName(event.target.value);

        if (typeof validated === 'undefined') {
            setSuccess(true);
        } else {
            setSuccess(false);
            setError(validated);
        }
    }

    let err = error;
    if (props.error && success === false) {
        err = props.error;
    } else if (success === true) {
        err = '';
    }

    return (
        <div className={classes.wrapper}>
            <InputText name={name} required change={props.change} value={props.value} blur={validate} error={err}/>
        </div>
    );
}

export default Name;