import validateEmail from "../components/Formular/Email/validateEmail";
import validateFirstname from "../components/Formular/Firstname/validateFirstname";
import validateName from "../components/Formular/Name/validateName";
import validateTelephone from "../components/Formular/Telephone/validateTelephone";

export default function validateContactForm(values) {
    let errors = {
        validated: true
    };

    errors.firstname = validateFirstname(values.firstname);
    if (typeof errors.firstname !== "undefined") {
        errors.validated = false
    }
    errors.name = validateName(values.name);
    if (typeof errors.name !== "undefined") {
        errors.validated = false
    }
    errors.email = validateEmail(values.email);
    if (typeof errors.email !== "undefined") {
        errors.validated = false
    }
    errors.telephone = validateTelephone(values.telephone);
    if (typeof errors.telephone !== "undefined") {
        errors.validated = false
    }

    return errors;
}