import React, {useRef, useState} from 'react';
import Popup from "reactjs-popup";
import {useTranslation} from 'react-i18next';
import classes from './LanguageSelector.module.scss';
import LanguageSelect from './LanguageSelect/LanguageSelect';
import de from '../../assets/images/de.jpg';
import ru from '../../assets/images/ru.jpg';
import ro from '../../assets/images/ro.jpg';
import en from '../../assets/images/en.jpg';
import pl from '../../assets/images/pl.jpg';
import uk from '../../assets/images/uk.jpg';
import {isMobile} from 'react-device-detect';
import classNames from "classnames/bind";


const LanguageSelector = (props) => {
    const {t, i18n} = useTranslation();
    const popup = useRef({});
    const [languageState, setLanguageState] = useState({
        languageSelected: i18n.language
    });
    const allowedLanguages = process.env.REACT_APP_LANGUAGES.split(',');
    let trigger;

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        popup.current.close();
        setLanguageState({
            languageSelected: language
        });
    }

    let cx = classNames.bind(classes);

    const contentClassNames = cx({
        LanguagePopup: true,
        isNotMobile: !isMobile
    });

    let languageSelect = [];
    if (props.languages) {
        languageSelect = props.languages.map(language => {
            if (allowedLanguages.includes(language.isoCode)) {
                return <LanguageSelect key={language.isoCode} changeLanguage={changeLanguage}
                                       language={language}/>
            }
        });
    }

    switch (languageState.languageSelected) {
        case 'EN':
            trigger = <img src={en} alt={t('default:languageFlag.enAlt')}/>;
            break;
        case 'RO':
            trigger = <img src={ro} alt={t('default:languageFlag.roAlt')}/>;
            break;
        case 'RU':
            trigger = <img src={ru} alt={t('default:languageFlag.ruAlt')}/>;
            break;
        case 'UK':
        case 'UA':
            trigger = <img src={uk} alt={t('default:languageFlag.ukAlt')}/>;
            break;
        case 'PL':
            trigger = <img src={pl} alt={t('default:languageFlag.plAlt')}/>;
            break;
        default:
        trigger = <img src={de} alt={t('default:languageFlag.deAlt')}/>;
        break;
    }

    const styling = {background: 'transparent', width: '100%', padding: '0 1rem', border: 'none', margin: '0'};
    const overlayStyling = {background: 'rgba(0,0,0,0.9)'};

    return (
        <Popup
            modal
            position='top center'
            ref={popup}
            trigger={trigger}
            contentStyle={styling}
            overlayStyle={overlayStyling}
            lockScroll={true}
            closeOnDocumentClick={false}>
            <div className={contentClassNames}>
                {languageSelect}
            </div>
        </Popup>
    )
}

export default LanguageSelector
