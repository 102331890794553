import React, {Component} from "react";
import iosButton from "../../assets/images/iosAppButton.png";
import androidButton from "../../assets/images/androidAppButton.png";
import classes from "./AppStore.module.scss";

const iosLink = 'https://apps.apple.com/de/app/karls-picker-app/id1564048250';
const androidLink = 'https://play.google.com/store/apps/details?id=de.karls.pickerapp';

class AppStore extends Component {
    componentWillMount() {
        if ((/Android/i.test(navigator.userAgent))) {
            window.location.href = androidLink;
        }
        if (/iPad|iPhone|iPod/i.test(navigator.userAgent)) {
            window.location.href = iosLink;
        }
    }

    render() {
        return (
            <div className={classes.appLink}>
                <a href={iosLink}><img
                    src={iosButton} alt=""/></a>
                <a href={androidLink}><img
                    src={androidButton} alt=""/></a>
            </div>
        );
    }
}

export default AppStore;